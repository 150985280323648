@import './_typography.scss';
@import './_utilities.scss';

.page--form {
  .page__section {
    &.grid--1-2 {
      @include respond-above(xs) {
        grid-template-columns: 1fr;
      }
      @include respond-above(sm) {
        grid-template-columns: 1fr;
      }
      @include respond-above(md) {
        grid-template-columns: 1fr 4fr;
      }
      @include respond-above(lg) {
        grid-template-columns: 1fr 2fr;
      }
    }
  }
}

.form-wizard {
  display: none;
  @include respond-above(sm) {
    display: block;
  }
  .form-wizard__step-container {
    @extend %btn;
    background: none;
    display: block;
    text-align: left;
    padding-left: var(--space-md);
    padding-top: var(--space-sm);
    padding-bottom: var(--space-sm);
    padding: 1rem 1.5rem;
    border-radius: var(--border-radius-lg);
    width: 100%;
    margin-bottom: var(--space-sm);

    &:not(.btn-primary):hover {
      background-color: var(--ui-gray-dark);
    }

    .form-wizard__step-number {
      opacity: 0.6;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    .form-wizard__step-name {
      margin-top: 0.25rem;
      @extend %h3;
    }

    &.active,
    &.active:hover {
      background-color: var(--primary-light);
      color: white;
    }
  }
}

.form-container {

  .form__card-container {
    padding: var(--space-md) 1.5rem;

    @include respond-above(xs) {
      padding: var(--space-lg);
    }
    .form__card-header {
      margin-bottom: var(--space-md);

      .form-container__step-number {
        opacity: 0.6;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .form-container__step-name {
        @include respond-below(xs) {
          font-size: 1.4rem;
        }
      }
    }
    .form__card-body {
      > div + div {
        margin-top: var(--space-sm);
      }

      @include respond-above (xs) {
        > div + div {
          margin-top: var(--space-md);
        }
      }
    }
    .form__card-footer {
      margin-top: var(--space-sm);
      @include respond-above(xs) {
        margin-top: var(--space-md);
      }

      .form__card-controls--desktop {
        display: none;
        justify-content: space-between;
        margin-bottom: 0;
        margin-top: var(--space-sm);
        width: 100%;
        @include respond-above(sm) {
          display: flex;
        }
        > *:only-child {
          margin-left: auto;
        }
        button {
          margin: 0;
        }

        svg {
          width: 1.4rem;
          height: 1.4rem;
        }
      }

      .form__card-center {
        display: flex;
        justify-content: center;
      }
    }
  }
}

input {
  font-family: inherit;
  width: 100%;
  // &:focus {
  //   box-shadow: 0 0 0 2px var(--primary-light) inset;
  // }
}

input[type='radio'] {
  // background-color: red;
  // appearance: none;
}

input[type='checkbox'] {
  width: 24px;
  height: 24px;
  margin-top: 0.25rem;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: var(--border-radius-sm);
  // border: solid 1px var(--ui-gray);
  background-color: var(--ui-blue);
  box-shadow: inset 0 1px 2px rgba(0,0,0,0.08);
  &:hover {
    cursor: pointer;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--primary-dark);
    border-radius: var(--border-radius-sm);
    transform: scale(0.95);
    opacity: 0;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.20);
  }
  &::after {
      // font-family: "Font Awesome 5 Free";
      // font-weight: 900;
      // content: '\f00c';
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      content: '\2713';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale(1.25);
      color: white;
      opacity: 0;
  }
  &:checked::before, &:checked::after {
      opacity: 1;
  }
}

.radioButton {
  @extend %btn;
  color: var(--text-dark);
  background-color: white;
  box-shadow: var(--box-shadow-light);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  font-size: 1rem;
  border: 1px solid var(--ui-gray-dark);
  border-right: 1px solid var(--ui-gray-dark);

  &.active {
    background-color: var(--primary-dark);
    border-color: var(--primary-dark);
    color: white;
  }

  &:first-of-type {
    border-top-left-radius: var(--border-radius-md);
    border-top-right-radius: var(--border-radius-md);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }

  @include respond-above(xs) {
    font-size: 1.125rem;
    &:first-of-type {
      border-top-left-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  
    &:last-of-type {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--border-radius-md);
      border-bottom-right-radius: var(--border-radius-md);
    }
  }
}
