@import './_config.scss';

// Container
.container {
  max-width: var(--max-width);
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
  box-shadow: var(--box-shadow);
}

// Text Styles
.text-xl {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

.text-lg {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
  font-weight: 700;
}

.text-md {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.text-sm-md {
  font-size: 1.125rem;
  margin-bottom: 0.75rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.text-sm {
  font-size: 1rem;
  // margin-bottom: 0.5rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.text-xs {
  font-size: 0.875rem;
  margin-bottom: 0.1rem;
  font-weight: 400;

  &.bold {
    font-weight: 700;
  }
}

.label {
  font-weight: 700;
  font-size: 0.9rem;
}

// Buttons
.btn, %btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1.25rem;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  color: var(--text-default);
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  // font-size: 1rem;
  
  outline: none;
  border-radius: var(--border-radius-md);
  transition: all 0.2s;
  text-align: center;
  line-height: 1.5;
  white-space: nowrap;

  .btn + .btn {
    margin-right: 0.5rem;
  }

  &.btn-full, %btn-full {
    width: 100%;
  }

  &:disabled {
    opacity: .8;
    cursor: not-allowed;
  }

  &.btn-small {
    padding: 0.6rem 1.25rem;
    font-size: 1rem;

    @include respond-above(xs) {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
  }

  &.btn-primary, %btn-primary {
    color: #fff;
    background-color: var(--primary-light);
    box-shadow: var(--box-shadow-sm);

    @include respond-above(sm) {
      &:hover {
        background-color: var(--primary-dark);
      }
    }
  }

  &.btn-primary-outline, %btn-primary-outline {
    background: none;
    box-shadow: 0 0 0 2px var(--text-default) inset;

    @include respond-above(sm) {
      &:hover {
        color: #fff;
        background: var(--primary-dark);
      }
    }
  }

  &.btn-secondary, %btn-secondary {
    color: #22293b;
    background-color: var(--secondary-default);
    // box-shadow: 0 0 0 2px var(--secondary-dark) inset;
    border: 2px solid var(--secondary-dark);
    box-shadow: var(--box-shadow-sm);
    @include respond-above(sm) {
      &:hover {
        filter:brightness(0.9);
      }
    }
  }

  &.btn-light, %btn-light {
    border: 2px solid var(--primary-dark);
    color: var(--text-default);
    background-color: var(--primary-light);
  }

  &.btn-alert, %btn-alert {
    background-color: var(--ui-alert);
    border: 2px solid var(--ui-alert-dark);
    color: white;
  }

  svg {
    margin-right: 0.6rem;
    margin-bottom: 1px;

    &:only-child {
      margin-right: 0;
    }
  }

  span {
    display: inline-block;
    margin-top: 1px;
  }
}

.btn-row-header, %btn-row-header {
  > .control-group {
    flex-direction: column;
    @include respond-above(xs) {
        flex-direction: row;
    }
  }
  button {
      width: 100%;
  }

  > .control-group + .control-group {
    margin-top: 1rem;
  }

  @include respond-above(sm) {
    flex-direction: row;
    button {
      width: auto;
    }
    > .control-group + .control-group  {
        margin-top: 0;
    }
  }
}

.control-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  > * {
    margin-right: 0;
    width: 100%;
    &:last-child {
      margin-right: 0;
    }
  }

  @include respond-above(xs) {
    flex-direction: row;
      > * {
        margin-right: 1.5rem;
      }
  }

  @include respond-above(sm) {
    width: auto;
    flex-grow: 1;
    > * {
      width: auto;
    }
  }

  > * + * {
    margin-top: 1rem;
    @include respond-above(xs) {
      margin-top: 0;
    }
  }
}

.control-group + .control-group {
  justify-content: flex-end;
}

// Alerts
.alert {
  padding: 0.8rem;
  margin: 1rem;
  opacity: 0.9;
  background-color: var(--primary-light);
  color: #333;
}

// Badges
.badge {
  font-size: 0.8rem;
  padding: 0.1rem;
  text-align: center;
  margin: 0.3rem;
  background-color: var(--ui-blue);
  color: var(--text-default);
  opacity: 0.3;
  border-radius: 4px;

  &.badge-primary {
    color: #fff;
    background: var(--primary-dark);
    box-shadow: inset 0px 1px 2px rgba(22, 38, 77, 0.2);
  }
}
